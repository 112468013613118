import { useState } from "react";
import {
  useTheme,
  Avatar,
  CardHeader,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useStoreValue } from "../../../store";
import { border_color, white } from "../../../theme/colors";

type OrdersTopBarProps = {};

export const OrdersTopBar: React.FC<OrdersTopBarProps> = () => {
  const { palette } = useTheme();
  const [storeState] = useStoreValue();

  const shortName =
    storeState.applicationLoginEmail && storeState.applicationLoginEmail[0];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const displayMenu = Boolean(anchorEl);

  const handleDisplayMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = "/login";
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <OrderTopBarWrapper className="flex items-center">
      <div>
        <img src={require("../../../imgs/Logo.png")} alt="" />
      </div>
      <div className="flex-grow"></div>
      <div>
        <CardHeader
          avatar={
            <Avatar
              onClick={handleDisplayMenu}
              className="cursor-pointer"
              sx={{
                bgcolor: palette.primary.main,
                textTransform: "uppercase",
              }}
            >
              {shortName}
            </Avatar>
          }
          title={<span className="capitalize">{process.env.REACT_APP_ENV}</span>}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={displayMenu}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Typography className="py-2 px-4">
            Account: {storeState.applicationLoginEmail}
          </Typography>

          <Divider />

          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </OrderTopBarWrapper>
  );
};

const OrderTopBarWrapper = styled.div`
  height: 64px;
  min-height: 64px;
  padding: 0px 24px;
  border: 1px solid ${border_color};
  box-shadow: 0px 1px 0px ${border_color};
  background-color: ${white};
`;
