import moment from "moment";
import { TableRow, TableCell } from "@mui/material";
import Checkbox from "../../../components/Checkbox";
import ToolTip from "../../../components/ToolTip";
import { ErrorIcon } from "../../../components/Icons";
import { CancellationFail } from "../../../types/cancellation.type";
import { Order, SelectedOrderSKUs } from "../../../types/order.type";
import { getTenderInfomation } from "../../../utils/getTenderInfomation";
import { useStoreValue } from "../../../store";

export const SignleSkuOrder = ({
  order,
  cancellationFailData,
  disabled,
  itemQty,
  selectedSKUs,
  handleCollectSKU,
  handleClearError,
}: {
  order: Order;
  cancellationFailData: {
    [order_id: string]: CancellationFail[];
  };
  disabled: boolean;
  itemQty: number;
  selectedSKUs: {} | SelectedOrderSKUs;
  handleCollectSKU: (order_id: string, web_sku_id: string) => void;
  handleClearError: (orderId: string) => void;
}) => {
  const [storeState, updateStore] = useStoreValue();

  const hasSkus: string[] =
    selectedSKUs[order.order_id as keyof typeof selectedSKUs];

  const isChecked = hasSkus
    ? hasSkus.includes(order.skus[0]?.web_sku_id)
    : false;

  const hasError =
    cancellationFailData[order.order_id] &&
    cancellationFailData[order.order_id].find(
      (item) => item?.web_sku_id === order.skus[0].web_sku_id
    );

  const tooltipElement = (orderId: string) => (
    <div className="flex p-1">
      <div className="text-sm border-r-2 pr-2 mr-2">{hasError?.message}</div>
      <div
        className="text-sm font-semibold cursor-pointer"
        onClick={() => handleClearError(orderId)}
      >
        Clear Error
      </div>
    </div>
  );

  const { latestRefundDate, remainingDays, tendersName } = getTenderInfomation({
    startDate: order.order_placed_date,
    tenders: storeState.tendersData,
    tenderTypes: order.tenders,
  });

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>
        <Checkbox
          disabled={disabled}
          checked={isChecked}
          onChange={() =>
            handleCollectSKU(order.order_id, order.skus[0].web_sku_id)
          }
        />
      </TableCell>
      <TableCell align="left">
        <div className="flex items-center">
          {hasError && (
            <ToolTip title={tooltipElement(order.order_id)}>
              <div className="pr-4">
                <ErrorIcon />
              </div>
            </ToolTip>
          )}

          <div>{order.order_id}</div>
        </div>
      </TableCell>
      <TableCell align="left">
        {order.order_placed_date && moment(new Date(order.order_placed_date)).format("DD MMM, yyyy")}
      </TableCell>
      <TableCell align="left">{order.skus[0].web_sku_id}</TableCell>
      <TableCell align="right">{itemQty}</TableCell>
      <TableCell align="right">{tendersName?.join(", ")}</TableCell>
      <TableCell align="right">{latestRefundDate}</TableCell>
      <TableCell align="right">{remainingDays}</TableCell>
    </TableRow>
  );
};
